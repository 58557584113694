var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "section",
    {
      staticClass: "twoActive",
    },
    [
      _c(
        "div",
        {
          staticClass: "twoActive-wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "bg-grid",
            },
            [
              _vm._m(0),
              _c(
                "ul",
                {
                  staticClass: "twoActive-cards",
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "twoActive-card",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "twoActive-card-title",
                        },
                        [_vm._v("活動一 | 指定方案 買一送一")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "twoActive-card-main",
                        },
                        [
                          _vm._m(1),
                          _vm._m(2),
                          _c(
                            "div",
                            {
                              staticClass: "note",
                            },
                            [
                              _vm._v(
                                "注意事項：詳細優惠方案內容，將由專人聯繫介紹"
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "reservation-btn",
                              attrs: {
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.scrollTop()
                                },
                              },
                            },
                            [_vm._v("免費搶先預約")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "twoActive-card",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "twoActive-card-title",
                        },
                        [_vm._v("活動二 | 不限航點來回機票免費抽")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "twoActive-card-main",
                        },
                        [
                          _vm._m(3),
                          _vm._m(4),
                          _vm._m(5),
                          _c(
                            "h3",
                            {
                              staticClass: "twoActive-step-title",
                            },
                            [_vm._v("如何英文健檢？")]
                          ),
                          _vm._m(6),
                          _vm._m(7),
                          _vm._m(8),
                          _vm._m(9),
                          _c(
                            "button",
                            {
                              staticClass: "reservation-btn",
                              attrs: {
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.scrollTop()
                                },
                              },
                            },
                            [_vm._v("預約免費英文健檢 開抽機票")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "h2",
      {
        staticClass: "twoActive-title",
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/anniversary_2023/twoActive/title.svg"),
            alt: "歡慶週年",
          },
        }),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "phone-gift",
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/anniversary_2023/twoActive/book.svg"),
            alt: "phone 15",
          },
        }),
        _c(
          "p",
          {
            staticClass: "phone-gift-descriptive",
          },
          [
            _vm._v("年度最殺優惠，"),
            _c("br", {
              staticClass: "d-lg-none",
            }),
            _vm._v("凡購買指定課程方案享"),
            _c(
              "span",
              {
                staticClass: "light-text",
                staticStyle: {
                  "font-weight": "700",
                },
              },
              [_vm._v("限量 買一送一")]
            ),
            _vm._v("！"),
            _c("br"),
            _vm._v("兩套全自用，或將一套分享親友共學，都ok!"),
          ]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "shopper-day",
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/anniversary_2023/twoActive/shoppingImg.svg"),
            alt: "購物節",
          },
        }),
        _c(
          "p",
          {
            staticClass: "shopper-day-descriptive",
          },
          [
            _c(
              "span",
              {
                staticClass: "light-text",
              },
              [_vm._v("12/26 17:00")]
            ),
            _vm._v("前購買，"),
            _c("br"),
            _vm._v("還能參加"),
            _c(
              "span",
              {
                staticClass: "light-text",
              },
              [_vm._v("台中購物節活動")]
            ),
            _c("br", {
              staticClass: "d-md-none",
            }),
            _vm._v("，抽百萬現金、汽車、豪宅"),
          ]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "twoActive-card-decoration",
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/anniversary_2023/twoActive/airplane-left.svg"),
            alt: "airplane-left",
          },
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/anniversary_2023/twoActive/kid-right.svg"),
            alt: "kid-right",
          },
        }),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "h3",
      {
        staticClass: "twoActive-card-subTitle break",
      },
      [
        _vm._v("免費英文健檢，領取能力"),
        _c("br", {
          staticClass: "d-md-none",
        }),
        _vm._v("分析報告"),
        _c("br", {
          staticClass: "d-none d-md-block",
        }),
        _vm._v("加碼抽機票"),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "p",
      {
        staticClass: "twoActive-card-descriptive",
      },
      [
        _vm._v("孩子習得一口好英文，再出國實戰開口說！"),
        _c("br"),
        _c(
          "span",
          {
            staticClass: "light-text",
          },
          [_vm._v("2024/1/7")]
        ),
        _vm._v(" 前完成免費英文健檢，加碼抽不限航點來回機票。"),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "ul",
      {
        staticClass: "twoActive-step",
      },
      [
        _c("li", [
          _c("h4", [_vm._v("step1")]),
          _c("img", {
            attrs: {
              src: require("@/assets/anniversary_2023/twoActive/step1.svg"),
              alt: "step1",
            },
          }),
          _c("p", [_vm._v("1分鐘填單"), _c("br"), _vm._v("領好禮")]),
        ]),
        _c("li", [
          _c("h4", [_vm._v("step2")]),
          _c("img", {
            attrs: {
              src: require("@/assets/anniversary_2023/twoActive/step2.svg"),
              alt: "step2",
            },
          }),
          _c("p", [
            _vm._v("3⽇內專⼈電話"),
            _c("br"),
            _vm._v("預約免費英文健檢"),
          ]),
        ]),
        _c("li", [
          _c("h4", [_vm._v("step3")]),
          _c("img", {
            attrs: {
              src: require("@/assets/anniversary_2023/twoActive/step3.svg"),
              alt: "step3",
            },
          }),
          _c("p", [_vm._v("免費體驗"), _c("br"), _vm._v("25 分鐘英文健檢")]),
        ]),
        _c("li", [
          _c("h4", [_vm._v("step4")]),
          _c("img", {
            attrs: {
              src: require("@/assets/anniversary_2023/twoActive/step4.svg"),
              alt: "step4",
            },
          }),
          _c("p", [_vm._v("獲得 6⼤英⽂能⼒"), _c("br"), _vm._v("報告書")]),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "h3",
      {
        staticClass: "twoActive-eligibility-title",
      },
      [
        _vm._v("怎麼知道自己符合"),
        _c("br", {
          staticClass: "d-md-none",
        }),
        _vm._v("機票抽獎資格？"),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "p",
      {
        staticClass: "twoActive-eligibility-subtitle",
      },
      [
        _vm._v("免費課程結束後的 3 小時內，將由系統寄發確認信；"),
        _c("br", {
          staticClass: "d-md-none",
        }),
        _vm._v("資格不符者，則不另行通知。"),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "twoActive-eligibility",
      },
      [
        _c("div", [
          _c("img", {
            attrs: {
              src: require("@/assets/anniversary_2023/twoActive/eligibility-mail.svg"),
              alt: "eligibility-mail",
            },
          }),
          _c("p", [_vm._v("打開電子信箱")]),
        ]),
        _c("div", [
          _c("img", {
            attrs: {
              src: require("@/assets/anniversary_2023/twoActive/eligibility-chech-mail.png"),
              alt: "eligibility-chech-mail",
            },
          }),
          _c("p", [
            _vm._v("確認是否收到"),
            _c("br"),
            _vm._v("獲得抽獎資格信件"),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }