var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "section",
    {
      staticClass: "ribbons",
    },
    [
      _c(
        "div",
        {
          staticClass: "celebrate-people-block",
        },
        [
          _c("img", {
            staticClass: "flag-left",
            attrs: {
              src: require("@/assets/anniversary_2023/ribbons/flag-left.svg"),
            },
          }),
          _c("img", {
            staticClass: "flag-right",
            attrs: {
              src: require("@/assets/anniversary_2023/ribbons/flag-right.svg"),
            },
          }),
          _c("SignForm", {
            attrs: {
              styleData: "green",
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [_c("h2", [_vm._v("週年慶活動結束囉！")])]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }