var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "main",
    [
      _c("Banner"),
      _c("TwoActive"),
      _c("FeatureComparison"),
      _c("Ribbons"),
      _c(
        "div",
        {
          staticClass: "gifts",
        },
        [
          _c(
            "div",
            {
              staticClass: "gifts-wrap",
            },
            [
              _c(
                "div",
                {
                  staticClass: "bg-grid",
                },
                [
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticClass: "tabs",
                    },
                    [
                      _c("ul", [
                        _c(
                          "li",
                          {
                            class: {
                              active: _vm.activeTab === "activeTab",
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                attrs: {
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.activeTab = "activeTab"
                                  },
                                },
                              },
                              [
                                _vm._v("英文能力"),
                                _c("br", {
                                  staticClass: "d-sm-none",
                                }),
                                _vm._v("分析報告"),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            class: {
                              active: _vm.activeTab === "books",
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                attrs: {
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.activeTab = "books"
                                  },
                                },
                              },
                              [
                                _vm._v("兒童英文"),
                                _c("br", {
                                  staticClass: "d-sm-none",
                                }),
                                _vm._v("繪本有聲書"),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "li",
                          {
                            class: {
                              active: _vm.activeTab === "topics",
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                attrs: {
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.activeTab = "topics"
                                  },
                                },
                              },
                              [
                                _vm._v("劍橋兒童"),
                                _c("br", {
                                  staticClass: "d-sm-none",
                                }),
                                _vm._v("英檢題庫"),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "gifts-lightbox",
                    },
                    [
                      _c("LightBox", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: ["topics"].includes(_vm.activeTab),
                            expression: '[ "topics" ].includes(activeTab)',
                          },
                        ],
                        attrs: {
                          activeTab: _vm.activeTab,
                        },
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeTab === "activeTab",
                              expression: "activeTab === 'activeTab'",
                            },
                          ],
                          staticClass: "analyze",
                        },
                        [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "text-white text-center font-weight-bold",
                            },
                            [_vm._v("孩子英文能力分析")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "text-white text-center",
                            },
                            [_vm._v("了解你現在的英文程度")]
                          ),
                          _c("img", {
                            attrs: {
                              src: require("@/assets/anniversary_2023/lightbox/analyze.svg"),
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeTab === "books",
                              expression: "activeTab === 'books'",
                            },
                          ],
                          staticClass: "videoWrap",
                        },
                        [_vm._m(1)]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("Carousel"),
              _vm._m(2),
            ],
            1
          ),
        ]
      ),
      _vm._m(3),
      _c(
        "button",
        {
          staticClass: "scrollTop",
          attrs: {
            type: "button",
          },
          on: {
            click: function ($event) {
              return _vm.scrollTop()
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/anniversary_2023/scrollTo.svg"),
              alt: "scrollTo",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/anniversary_2023/scrollTo-hover.svg"),
              alt: "scrollTo",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "gifts-title",
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/anniversary_2023/gifts/loudspeaker.svg"),
            alt: "loudspeaker",
          },
        }),
        _c("h2", [_vm._v("好禮領取搶先看")]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "video",
      {
        attrs: {
          controls: "",
          autoplay: "",
          name: "media",
          width: "100%",
        },
      },
      [
        _c("source", {
          attrs: {
            src: "https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/video/2023_jr_king_arthur_legend_the_sword_in_the_stone_voice_book_trailer",
            type: "video/mp4",
          },
        }),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "note",
      },
      [
        _c("h2", [_vm._v("機票抽獎 活動注意事項")]),
        _c(
          "div",
          {
            staticClass: "note-item",
          },
          [
            _c("h3", [_vm._v("一、抽獎資格相關問題")]),
            _c("p", [
              _vm._v(
                "1. 本活動時間為 2023 / 12 / 4 (一) 至 2024 / 1 / 7 (日)，於期間內完成 WUWOW Jr 課程試聽之台灣居民，且非 WUWOW Jr 在期之學員始獲得抽獎資格。"
              ),
            ]),
            _c("p", [
              _vm._v(
                "2. 參與活動者請正確填寫表單資料，填寫後5日內將有專人聯繫；若資料有以下情形，將取消抽獎資格： (1)資料填寫有誤，電話空號，無法聯繫 (2)非本人填寫"
              ),
            ]),
            _c("p", [
              _vm._v(
                "3.每人僅限一次抽獎名額，重複填寫無法增加中獎機率，請勿重複填寫。"
              ),
            ]),
            _c("p", [
              _vm._v(
                "4.符合機票抽獎資格者，將會寄送資格認證信；資格不符者，則不另行通知。"
              ),
            ]),
            _c("p", [
              _vm._v(
                "5.任何抽獎資格判定問題，請來信 md6@lioshutan.com 並附上您的姓名/手機/e-mail ，將有專人協助查詢。"
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "note-item",
          },
          [
            _c("h3", [_vm._v("二、得獎相關問題")]),
            _c("p", [_vm._v("1.獎項為機票折抵現金 NT$20,000 元，共計乙名。")]),
            _c("p", [
              _vm._v(
                "2.得獎者名單將統一於 2024 / 1 / 31 (三) 公布於此活動頁。"
              ),
            ]),
            _c("p", [
              _vm._v(
                "3.若經查中獎人未全數符合活動要求，則不具得獎資格，亦不遞補中獎名單。"
              ),
            ]),
            _c("p", [
              _vm._v(
                "4.本抽獎活動屬於機會中獎，依財政部國稅局制定之「各類所得扣繳率標準」規定，中獎者若未滿20歲，須獲得法定監護人同意及代為領取。凡中獎價值超過新台幣$1,000元者，獎項將須列入個人年度綜合所得稅申報(稅額以獎品市價計算)。中獎價值超過新台幣$20,000元者，須繳納10%稅金(稅額以獎品市價計算) 。【六書堂數位學習股份有限公司】將於中獎名單公告一個月內開立、寄發扣繳憑單、中獎領據至中獎者信箱。中獎者若逾時或未提供完整領獎資訊者，視同放棄領獎資格，亦不遞補中獎資格。（若因信箱填寫有異，遺失/無法收取中獎領據等造成逾期影響領獎程序，視同放棄領獎、亦不遞補中獎資格。)"
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "note-item",
          },
          [
            _c("h3", [_vm._v("三、其他規則")]),
            _c("p", [
              _vm._v(
                "1.參與活動之消費者，如有違反本活動規則及辦法，或以惡意之電腦程式或其他明顯違反活動公平性之方式，意圖混淆或影響活動結果者，一經主辦單位發現或經第三人檢舉經查證屬實，主辦單位有權利立即取消其中獎資格，除追回獎品外，並對於任何破壞本活動之行為追溯相關法律責任。"
              ),
            ]),
            _c("p", [
              _vm._v(
                "2.活動辦法載明在本活動平臺中，本活動如有未盡事宜，悉依民事法律相關規定辦理，主辦單位保有隨時更改活動細節（參加方式及贈品內容、數量等）、保留、暫停及解釋內容之權利，並可取消任何影響他人中獎權益之中獎者的中獎資格，參加者同意完全依照及遵守本活動之更動決定，絕無異議。修改訊息將於本平臺上公布為準，不另行通知。"
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "note-item",
          },
          [
            _c("h3", [_vm._v("四、機票獎項 注意事項")]),
            _c("p", [
              _vm._v(
                "1.本公司提供獎品僅為機票折抵現金 NT$20,000，若因任何異動及消費者行為所遺失獎金領取，一概不補發。"
              ),
            ]),
            _c("p", [
              _vm._v(
                "2.購買機票及航空公司異動、班機延誤...等多項任何旅途異常相關事項，請依航空公司和現行法規為主，本公司一概不承擔任何飛行、旅途、行李...等多項風險補償和補發獎金。"
              ),
            ]),
            _c("p", [
              _vm._v(
                "3.若中獎人不符合、不同意、或發現惡意舞弊或盜用他人身份資料進行抽獎活動等情事，主辦單位保有取消中獎資格及參與本活動之權利。"
              ),
            ]),
          ]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c("footer", [
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "mailto:service@wuwow.tw",
              },
            },
            [_vm._v(" 聯絡信箱 service@wuwow.tw")]
          ),
        ]),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "tel:0800-885-315",
              },
            },
            [_vm._v("免費諮詢電話 0800 - 885 - 315")]
          ),
        ]),
      ]),
      _c("p", [_vm._v("隱私權政策")]),
      _c("p", [
        _vm._v("Copyrights © 2022 六書堂數位學習"),
        _c("br", {
          staticClass: "d-md-none",
        }),
        _vm._v("LIOSHUTAN. All Rights Reserved"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }